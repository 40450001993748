<template>
  <div>
    <div class="view-bar-body" v-loading="loading" element-loading-text="拼命加载中" style="padding: 30px">
      <view-head :showBtn="showBtn"></view-head>
      <view-tabs :active-index="activeIndex" @change-tab="changeTab" @refresh-tab="refreshTab"></view-tabs>
      <div class="view-bar-body-content">
        <!-- 实名认证 -->
        <view-verify v-if="activeIndex === ''"></view-verify>

        <!-- 全景雷达 -->
        <view-unify v-if="activeIndex === 'UNIFY'" @refresh-tab="refreshTab" ref="view-tab-UNIFY"></view-unify>
        <!-- 探针C -->
        <view-behavior v-if="activeIndex === 'BEHAVIOR'" @refresh-tab="refreshTab"
          ref="view-tab-BEHAVIOR"></view-behavior>
        <!-- 全景档案 -->
        <view-archives v-if="activeIndex === 'ARCHIVES'" @refresh-tab="refreshTab"
          ref="view-tab-ARCHIVES"></view-archives>
        <view-history v-if="activeIndex === 'HISTORY'" @refresh-tab="refreshTab" ref="view-tab-HISTORY"></view-history>
        <!-- 天创黑名单 -->
        <view-blacklist v-if="activeIndex === 'BLACKLIST'" @refresh-tab="refreshTab"
          ref="view-tab-BLACKLIST"></view-blacklist>
        <!-- 信贷保镖 -->
        <view-bodyguard v-if="activeIndex === 'BODYGUARD'" @refresh-tab="refreshTab"
          ref="view-tab-BODYGUARD"></view-bodyguard>
        <!-- 综合信用 -->
        <view-comprehensive v-if="activeIndex === 'COMPREHENSIVE'" @refresh-tab="refreshTab"
          ref="view-tab-COMPREHENSIVE" />
        <!-- 天狼星报告 -->
        <view-lightning v-if="activeIndex === 'LIGHTNING'" @refresh-tab="refreshTab" ref="view-tab-LIGHTNING" />
        <!-- 星耀报告 -->
        <view-starlight v-if="activeIndex === 'STARLIGHT'" @refresh-tab="refreshTab" ref="view-tab-STARLIGHT" />
        <!-- 高级报告 -->
        <view-purple v-if="activeIndex === 'PURPLE'" @refresh-tab="refreshTab" ref="view-tab-PURPLE" />
        <!-- 个人消费画像Pro -->
        <view-portrait v-if="activeIndex === 'PORTRAIT'" @refresh-tab="refreshTab" ref="view-tab-PORTRAIT" />
        <!-- 探查（综合版） -->
        <view-explored v-if="activeIndex === 'EXPLORED'" @refresh-tab="refreshTab" ref="view-tab-EXPLORED" />
        <!-- 借条综合 -->
        <view-note v-if="activeIndex === 'NOTE'" @refresh-tab="refreshTab" ref="view-tab-NOTE" />
        <!-- 多借条 -->
        <view-more-note v-if="activeIndex === 'MORE_NOTE'" @refresh-tab="refreshTab" ref="view-tab-MORE_NOTE" />
      </div>
    </div>
  </div>
</template>
<script>
import viewHead from "./reports/view-head"
import viewTabs from "./reports/view-tabs"
import viewVerify from "./reports/view-verify"

import viewUnify from "./reports/view-unify"
import viewBehavior from "./reports/view-behavior"
import viewArchives from "./reports/view-archives"
import viewHistory from "./reports/view-history"
import viewBlacklist from "./reports/view-blacklist"
import viewBodyguard from "./reports/view-bodyguard"
import viewComprehensive from "./reports/view-comprehensive"
import viewLightning from "./reports/view-lightning"
import viewStarlight from "./reports/view-starlight"
import viewPurple from "./reports/view-purple"
import viewPortrait from "./reports/view-portrait"
import viewExplored from "./reports/view-explored"
import viewNote from "./reports/view-note"
import viewMoreNote from "./reports/view-more-note"

import view from "./view.js";

import code from "@/mixins/sms";
import service from "./api";
import tabs from "./tabs";
import gt from "@/mixins/geetest";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [code, view],
  props: {
    showView: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      jiluObj: {},
      showBtn: false,
      timer: null,
      showDesens: false,
      form: {
        mobile: "",
        yzm: "",
      },
      seconds: 0,
      width: "90%",
      tmwidth: "25%",
      captcha: false,
    };
  },
  components: {
    viewHead,
    viewTabs,
    viewVerify,
    viewUnify,
    viewBehavior,
    viewArchives,
    viewHistory,
    viewBlacklist,
    viewBodyguard,
    viewComprehensive,
    viewLightning,
    viewStarlight,
    viewPurple,
    viewPortrait,
    viewExplored,
    viewNote,
    viewMoreNote
  },
  created () {
    this.getDetail();
    if (document.documentElement.clientWidth < 480) {
      this.width = "95%";
      this.tmwidth = "90%";
    }
  },
  computed: {
    ...mapState("userReport", ["activeIndex", "price", "loading"]),
    ...mapState("userReport/verify", ["userInfo", "desensitizationData"]),
  },
  methods: {
    ...mapMutations("userReport", [
      "setIndex",
      "setRange",
      "setIsBlack",
      "setBlackTag",
      "setShowBlack",
    ]),
    ...mapActions("userReport", ["checkPrice", "getUserblacklist"]),
    ...mapActions("userReport/verify", [
      "getMapList",
      "drawMap",
      "getLoginData",
      "getVerify",
      "getList",
      "getPerson",
    ]),
    changeTab (index, refresh = false) {
      // 查找 refs
      const refTag = `view-tab-${index}`;
      // if(refTag==='view-tab-MORE_NOTE'){
      //     this.$message({
      //       type: "warning",
      //       message: "功能升级中，暂不可用",
      //       duration: 1000,
      //     })
      //     return
      //   }
      const trigger = () => {
        // 切换标签页
        this.setIndex(index);
        // 如果 tab 存在，并且需要刷新
        if (refresh && this.$refs.hasOwnProperty(refTag)) {
          const target = this.$refs[refTag];
          // 判断 tab 是否包含 load 方法
          if (
            target &&
            target.hasOwnProperty("load") &&
            typeof target.load === "function"
          ) {
            // 调用 load
            target.load(true);
          }
        }
      };
      const tab = tabs.filter((i) => i.id === index).pop();
      if (tab && tab.price > 0) {
        this.checkPrice({
          tab: tab,
          refresh,
        }).then((res) => {
          res && trigger();
        });
      } else {
        trigger();
      }
    },
    refreshTab (index) {
      this.changeTab(index || this.activeIndex, true);
    },
    getDetail () {
      let flId = this.$route.query.flId;
      return service.superInfo({ superUserId: this.getId() }).then((res) => {
        service.detail(flId).then((res) => {
          document.title = `${res.username} - 查看报告`;
          let data = { superInfo: res, flInfo: res }
          this.handleView(data);
          this.getLoginData();
          this.getList({ pageNum: 1, pageSize: 10 });
        })
      });
    },
    getId () {
      let storage = sessionStorage.getItem("xinyou_sh_user");
      let user = JSON.parse(storage) || {};
      let { parentId = null, id = null } = user;
      // 子账户
      if (parentId) return parentId;
      return id;
    },
    // 关闭
    onClose () {
      this.showBtn = false;
      clearTimeout(this.timer);
      this.setShowBlack(false);
      this.setIsBlack("");
      this.setBlackTag([]);
      this.$emit("handleClose");
    },
    initGt () {
      return gt.register().then((captcha) => {
        this.captcha = captcha;
        return captcha;
      });
    },
    //去脱敏
    openDesens () {
      let promise = new Promise((resolve, reject) => resolve());

      if (!this.form.mobile) {
        promise = service.selfInfo().then((res) => {
          this.form.mobile = res.mobile;
        });
      }

      promise
        .then(async () => {
          await this.initGt().then(() => {
            this.showDesens = true;
            this.form.yzm = "";
          });
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "获取脱敏参数失败",
            duration: 1000,
          });
        });
    },
    desensConfirm () {
      let userId = this.userInfo.userId;
      let code = this.form.yzm;
      service
        .Desens({ userId, code })
        .then((res) => {
          this.form.yzm = "";
          this.showDesens = false;
          this.getVerify(false);
          if (this.activeIndex == "operator") {
            this.getOperator(true);
            this.getEmergency(); //刷新紧急联系人
          } else if (this.activeIndex == "contacts") {
            this.getList({ pageSize: 10, pageNum: 1 }); //刷新通讯录
          } else if (this.activeIndex == "") {
            this.getMapList({ pageNum: 1, pageSize: 10 }); //刷新地图列表
            this.getLoginData();
          }
        })
        .catch(() => {
          this.form.yzm = "";
          this.initGt();
        });
    },
    desensCancel () {
      this.showDesens = false;
      this.form.yzm = "";
    },
    onGetCodeButtonClick () {
      this.captcha
        .onReady(function () { })
        .onSuccess(() => {
          let result = this.captcha.getValidate();
          if (!result) return;
          // 发送验证码
          let { mobile = "" } = this.form;
          if (this.seconds > 0) return;
          clearTimeout(this.timer);
          service.getCode({ phone: mobile, ...result }).then(() => {
            this.till = new Date().getTime() + 60 * 1000;
            this.countDown();
          });
        });
      this.captcha.verify();
    },
  },
};
</script>
<style lang="scss">
.view-bar {
  position: relative;

  &-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
  }

  &-body {
    // background-color: rgba(247, 248, 252, 1);
    padding: 10px;

    &-content {
      padding: 20px;
      overflow: hidden;
      background-color: rgba(247, 248, 252, 1);
    }
  }

  .tuomin {
    position: absolute;
    top: 95px;
    right: 40px;
  }
}

.denses {
  .code {
    position: relative;

    .el-input {
      width: 90%;
    }

    .code-btn {
      position: absolute;
      right: 60px;
    }
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-dialog__body {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .view-bar .tuomin {
    top: 3.5rem;
  }
}
